import { RouteSectionProps } from "@solidjs/router";
import { ErrorBoundary } from "solid-js";
import { isServer } from "solid-js/web";
import HubbleErrorV2 from "~/components/error_v2";
import { captureErrorInSentry } from "~/utils/third_party/sentry";

export default function Gifting(props: RouteSectionProps) {
  const giftingKey = props.params.giftingKey;
  return (
    <ErrorBoundary
      fallback={(err) => {
        if (!isServer) {
          captureErrorInSentry(err);
        }

        return (
          <HubbleErrorV2
            errorMessage={err.message}
            class="dark"
            primaryButton={{
              text: "Go home",
              onClick: () => {
                if (giftingKey) {
                  window.location.href = `/gift-box/${giftingKey}`;
                } else if (props.location.pathname.includes("pluto/create")) {
                  window.location.href = "/pluto/create/new";
                } else {
                  window.location.href = "/";
                }
              },
            }}
          />
        );
      }}
    >
      {props.children}
    </ErrorBoundary>
  );
}
