import { JSX, Show } from "solid-js";
import { cardGenerationFailed } from "~/assets/assets";
import { openIntercom } from "~/utils/third_party/intercom";

type ButtonProps = {
  text: string;
  onClick: () => void;
};

type HubbleErrorV2Props = {
  primaryButton?: ButtonProps;
  secondaryButton?: ButtonProps;
  title?: string;
  errorMessage?: string;
  class?: string;
};

export default function HubbleErrorV2(props: HubbleErrorV2Props) {
  const primaryButtonDefaultProps = {
    text: "Go home",
    onClick: () => {
      window.location.href = "/";
    },
  };

  const secondaryButtonDefaultProps = {
    text: "Contact support",
    onClick: () => {
      openIntercom();
    },
  };

  const primaryButtonProps = props.primaryButton ?? primaryButtonDefaultProps;

  const secondaryButtonProps =
    props.secondaryButton ?? secondaryButtonDefaultProps;

  return (
    <div
      class="flex h-full w-full"
      classList={{
        [props.class ?? ""]: true,
      }}
    >
      <div class="flex h-full w-full items-center justify-center bg-baseTertiaryLight text-center dark:bg-black dark:text-textWhite">
        <div class="flex w-full flex-col items-center justify-center px-5 sm:max-w-[444px]">
          <img
            src={cardGenerationFailed}
            class="h-[120px] w-[150px]"
            alt="Something went wrong"
          />
          <div class="mt-5"></div>
          <span class="text-h3 ">{props.title ?? "Something went wrong!"}</span>
          <Show when={props.errorMessage}>
            <div class="mt-2"></div>
            <span class="text-medium text-textNormal">
              {props.errorMessage}
            </span>
          </Show>
          <div class="mt-5"></div>
          <div class="flex w-full flex-row items-center justify-center gap-[15px]  sm:min-w-[350px]">
            <Button onClick={primaryButtonProps.onClick}>
              {primaryButtonProps.text}
            </Button>
            <Show when={secondaryButtonProps}>
              <Button onClick={secondaryButtonProps.onClick}>
                {secondaryButtonProps.text}
              </Button>
            </Show>
          </div>
        </div>
      </div>
    </div>
  );

  function Button(props: { children: JSX.Element; onClick: () => void }) {
    return (
      <div class="flex flex-1">
        <button
          class="h-10 w-full cursor-pointer rounded-full border border-baseTertiaryDark px-4 py-[7px] text-mediumBold dark:border-basePrimaryDark dark:bg-basePrimaryDark"
          onClick={props.onClick}
        >
          {props.children}
        </button>
      </div>
    );
  }
}
